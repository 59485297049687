<template>
      <transition name="modal-fade">
            <div class="element" @click="close($event)">
                  <div class="modal-backdrop" :class="{'shown-backdrop' : active}" >
                  </div>
                  <Btn id="close-modal" class="close" size="sm" variant="black" icon="close" @click.native="$emit('close')"/>
                  <div
                        class="modal"
                  >
                        <header v-if="title" class="modal-header" id="modalTitle">
                              <span class="full-width">{{title}}</span>
                        </header>
                        <section class="modal-body" id="modalDescription">
                              <slot name="body">
                                    This is the default body!
                              </slot>
                        </section>

                        <footer v-if="$slots.modalFooter" class="modal-footer">
                              <slot name="modalFooter">
                                    This is the default footer!
                              </slot>
                        </footer>
                  </div>
            </div>
      </transition>
</template>

<script>
import Btn from '@b/button/Button'
      export default {
            name: "Modal",
            components: {Btn},
            props: {
                  active: {
                        type: Boolean,
                        default: true
                  },
                  title: {
                        type: String,
                        default: ''
                  }
            },
            methods: {
                  close(e){
                        if(e.target.className.includes('modal-backdrop')) {
                              this.$emit('close')
                        }
                  },
                  focusOnInput(){
                        !!document.getElementById('email-input') && document.getElementById('email-input').querySelector('input').focus()
                  }
            },
            mounted(){
                  this.focusOnInput()
            },
            beforeDestroy(){ 
                  // document.body.classList.remove('modal-shown');
                  // document.body.removeChild(document.querySelector('.overlay'))
                  // document.querySelector('#close-modal').removeEventListener('click', ()=> {
                  //       console.log(123123)
                  // })
            }
      };
</script>

<style lang="scss">

.element {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 2%;
      @media screen and (max-width: 576px){
            // padding: 15%;
      }
      @media screen and (max-width: 1400px){
            padding: 5%;
      }
}

.modal-backdrop {
      // pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(101, 114, 173, 0.15);
      cursor: pointer;
      backdrop-filter: blur(2px);
      opacity: 1;
      transition: opacity .33s;
      z-index: 200;
}

.modal {
      background: #ffffff;
      box-shadow: 2px 2px 20px 1px;
      overflow-x: auto;
      position: sticky;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      top: 0;
      z-index: 250;

      @media screen and (max-width: 413px){
            height: 90%;
            top: 4%;
      }
}

.modal-header,
.modal-footer {
      padding: 15px;
      display: flex;
      width: 100%;
}

.modal-header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center ;
      font-family: 'Vaud';
      text-align: center;
      @include toRem(font-size, 45);

      span {
            width: 50%;
      }

      @media screen and (max-width: 576px){
            @include toRem(font-size, 30);
      }
}

.modal-footer {
      border-top: 1px solid #eeeeee;
      justify-content: center;
      align-items: center;
}

.modal-body {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 20px 10px;
      height: 100%;
      display: flex;
      overflow: hidden;

      .image-modal {
            height: 100%;
      }
}

.btn-close {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      font-size: 20px;
      padding: 10px;
      cursor: pointer;
      font-weight: bold;
      color: #4aae9b;
      background: transparent;
}

.btn-green {
      color: white;
      background: #4aae9b;
      border: 1px solid #4aae9b;
      border-radius: 2px;
}

.close {
      width: 60px;
      height: 60px;
      z-index: 255;
      right: 60px;
      top: 6%;
      position: absolute;

      @media screen and (max-width: 1366px){
            right: 81px;
            top: 12%;
      }

      @media screen and (max-width: 576px){
            right: 10%;
      }

      .wrapper {
            display: flex;
            justify-content: center;

            span {
                  display: none;
            }
      }
}


.modal-fade-enter,
.modal-fade-leave-to {
      opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
      transition: opacity 0.5s ease;
}
</style>